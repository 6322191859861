import React, { useEffect } from 'react';
import {
  Box,
  Divider,
  Text,
  TextButton,
  Heading,
  Breadcrumbs,
} from '@wix/design-system';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { getSubCategoryHeaderPageData } from './sub-category-page-data';
import type { SubCategoryPageHeaderData } from './sub-category-page-data';
import { AppMarketPage } from '../../enums';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import {
  appMarketBackButtonClick,
  appMarketPageView,
  appMarketCategoryClickSrc24Evid185,
} from '@wix/bi-logger-app-market-data/v2';
import { Route, RoutePath } from '../../models';
import { SubCategoryHeaderSkeleton } from './sub-category-header-skeleton';
import { BackButton } from '../../components/back-button';

function SubCategoryPageHeaderComponent({
  slug,
  parentSlug,
  parentName,
  primaryTitle: title,
}: SubCategoryPageHeaderData) {
  const { routerData } = useJunkYard();
  const { baseURL } = useSiteContext();
  const { biLogger } = useEssentials();
  const { router } = useRouterContext();
  const page = AppMarketPage.SUB_CATEGORY;

  // PageView reports from PageHeader because its required title and parentName.
  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          urlParams,
          pageType: AppMarketPage.SUB_CATEGORY,
          pageName: parentName,
          subCategories: [title],
        }),
      ),
    );
  }, []);

  function onBack() {
    biLogger.report(
      appMarketBackButtonClick({
        tag_type: page,
        tag_name: slug,
        location: 'sub_category_page',
      }),
    );
    router.navigateTo({
      path: RoutePath.BACK,
      payload: undefined,
    });
  }

  function getCategoryHref(categorySlug: string) {
    const hasHref = router.hasHref();
    if (!hasHref) {
      return undefined;
    }
    const route = new Route({
      path: RoutePath.CATEGORY,
      payload: {
        slug: categorySlug,
      },
      baseURL,
    });
    return route.toHref();
  }

  function onButtonClick(_slug: string) {
    biLogger.report(
      appMarketCategoryClickSrc24Evid185({
        tag_type: 'category',
        tag_name: parentSlug,
        sub_tag_name: _slug,
      }),
    );
    router.navigateTo({
      path: RoutePath.CATEGORY,
      payload: {
        slug,
      },
    });
  }

  return (
    <>
      <Box dataHook="breadcrumbs" marginTop="24px">
        {router.hasHistory() && (
          <>
            <Box verticalAlign="middle">
              <BackButton
                onBack={onBack}
                size="small"
                weight="thin"
                skin="dark"
              />
            </Box>
            <Box margin="0px 12px 0px 18px" height="12px" alignSelf="center">
              <Divider direction="vertical" />
            </Box>
          </>
        )}
        <Breadcrumbs
          items={[
            {
              id: 0,
              value: 'Category',
              customElement: (
                <TextButton
                  dataHook="category-parent-button"
                  size="small"
                  weight="normal"
                  as="a"
                  href={getCategoryHref(parentSlug)}
                  onClick={(e) => {
                    onButtonClick(parentSlug);
                    e.preventDefault();
                  }}
                >
                  {parentName}
                </TextButton>
              ),
            },
            {
              id: 1,
              value: title,
              customElement: (
                <Text weight="thin" size="small" secondary disabled>
                  {title}
                </Text>
              ),
            },
          ]}
          size="medium"
        />
      </Box>
      <Box marginTop="6px">
        <Heading size="extraLarge" dataHook="sub-category-page-title">
          {title}
        </Heading>
      </Box>
    </>
  );
}

export function SubCategoryPageHeader({
  slug,
  parentSlug,
}: {
  slug: string;
  parentSlug: string;
}) {
  const { i18n } = useEssentials();
  const services = useServices();

  return (
    <QueryDecorator
      queryFn={() =>
        getSubCategoryHeaderPageData({
          slug,
          parentSlug,
          services,
          languageCode: i18n.language,
        })
      }
      queryKey={`sub-category-header-${i18n.language}-${slug}-${parentSlug}`}
    >
      {({ data, isLoading }) =>
        isLoading ? (
          <SubCategoryHeaderSkeleton />
        ) : (
          <SubCategoryPageHeaderComponent {...data} />
        )
      }
    </QueryDecorator>
  );
}
