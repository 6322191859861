import * as React from 'react';
import type { IModalProps } from '../modals';
import {
  Modal,
  ModalMobileLayout,
  Heading,
  Text,
  Box,
  Button,
} from '@wix/design-system';
import type { TFunction } from 'i18next';
import { appMarketMobileAlertForInstallMoveToDesktop } from '@wix/bi-logger-app-market-data/v2';

interface InstallationNotSupportedProps extends IModalProps {
  t?: TFunction;
}
import { biLogger } from '../../../bi';
import { composer } from '../../../component-decorators/composer';
import { config } from '../../../config';

function InstallationNotSupportedMobileBase({
  t,
  appName,
  marketplaceStore,
  msid,
  appId,
}: InstallationNotSupportedProps) {
  function handleClose() {
    return marketplaceStore.closeAllModals();
  }

  React.useEffect(() => {
    biLogger.report(
      appMarketMobileAlertForInstallMoveToDesktop({
        app_id: appId,
        msid,
      }),
    );
  });

  function getContent() {
    return (
      <Box
        direction="vertical"
        align="center"
        textAlign="center"
        marginTop="103px"
        marginLeft="24px"
        marginRight="24px"
      >
        <img
          src={`${config.marketplaceStaticsUrl}assets/images/mobile-install-block-mobile.svg`}
        />
        <Box marginTop="46px" />
        <Heading appearance="H2">
          {t('modals-installationNotSupportedMobile-title')}{' '}
        </Heading>
        <Box marginTop="12px" marginBottom="36px">
          <Text skin="standard" secondary weight="thin">
            {t('modals-installationNotSupportedMobile-content', { appName })}{' '}
          </Text>
        </Box>
        <Button onClick={handleClose}>
          {' '}
          {t('modals-installationNotSupportedMobile-cta')}{' '}
        </Button>
      </Box>
    );
  }

  return (
    <Modal isOpen={true} zIndex={100000} screen="full">
      <ModalMobileLayout
        onCloseButtonClick={handleClose}
        content={getContent()}
        fullscreen
      />
    </Modal>
  );
}

const InstallationNotSupportedMobile = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose((props: InstallationNotSupportedProps) => (
    <InstallationNotSupportedMobileBase {...props} />
  ));

export {
  InstallationNotSupportedProps,
  InstallationNotSupportedMobile,
  InstallationNotSupportedMobileBase,
};
