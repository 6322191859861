import React from 'react';
import { Box, SidebarItemNext } from '@wix/design-system';
import { useMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { config } from '../../config';
import { Origin, Path } from '../../enums/marketplace-enums';
import { AppMarketPage } from '../../types/common/marketplace';
import { useConfigContext } from '../../config-context';
import { biLogger } from '../../bi';
import {
  appMarketBackToManageAppsSrc24Evid5,
  appMarketMenuNavigation,
} from '@wix/bi-logger-app-market-data/v2';
import { ChevronLeft } from '@wix/wix-ui-icons-common';
import { SearchBoxContainer as SearchBox } from '../search-box';
import { SidebarVisibilityToggle } from '../sidebar-visibility-toggle';
import { composer } from '../../component-decorators/composer';
import type { TFunction } from 'i18next';

export type SidebarHeaderProps = {
  experiments: any;
};

export function SidebarHeader({ experiments }: SidebarHeaderProps) {
  const { locale: languageCode = 'en' } = useConfigContext();
  const { route, metaSiteId } = useMarketplaceStore();

  function shouldShowSidebarCollapseButton() {
    return config.origin === Origin.BIZMGR;
  }

  function shouldShowSearchBox() {
    return config.origin !== Origin.STANDALONE;
  }

  function onBackClick() {
    biLogger.report(
      appMarketMenuNavigation({
        menu_item_label: config.goBackToReferrer?.labelKey,
      }),
    );

    biLogger.report(
      appMarketBackToManageAppsSrc24Evid5({
        click_origin: 'top',
        msid: metaSiteId,
        tag_name: (route?.slug || route?.query || 'homepage').replace(
          /-/g,
          '_',
        ),
        tag_type:
          route?.path === Path.HOME || ''
            ? AppMarketPage.HOMEPAGE
            : route?.path === Path.WEB_SOLUTION
            ? AppMarketPage.APP_PAGE
            : route?.path,
        referral_name: config.goBackToReferrer?.referrerNameForBI,
      }),
    );

    config.goBackToReferrer?.navigation();
  }

  return (
    <Box direction="vertical" paddingTop="8px">
      {shouldShowSidebarCollapseButton() ? <SidebarVisibilityToggle /> : null}

      {!shouldShowSidebarCollapseButton() && config.goBackToReferrer ? (
        <SidebarItemNext itemKey="back-to-referrer" onClick={onBackClick}>
          <Box verticalAlign="middle">
            <ChevronLeft />
            <TranslatedKey tkey={config.goBackToReferrer.labelKey ?? ''} />
          </Box>
        </SidebarItemNext>
      ) : null}

      {shouldShowSearchBox() ? (
        <Box
          align="center"
          verticalAlign="middle"
          padding="18px 20px 6px"
          paddingBottom="10px"
        >
          <SearchBox
            isInMenu={true}
            locale={languageCode}
            searchLocation="menu"
          />
        </Box>
      ) : null}
    </Box>
  );
}

export const TranslatedKey = composer()
  .withTranslation()
  .compose(({ t, tkey }: { t?: TFunction; tkey: string }) => t?.(tkey));
