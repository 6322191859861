import type {
  IServices,
  IAppsPricingPlans,
  SupportedAppStatuses,
} from '@wix/app-market-services';
import { AppPricingPlans } from '@wix/app-market-services';

export async function getAppPricingPlans({
  services,
  appId,
  languageCode,
  status,
}: {
  services: IServices;
  appId: string;
  languageCode: string;
  status: SupportedAppStatuses;
}): Promise<IAppsPricingPlans> {
  if (status === 'DRAFT') {
    const pricingModel = await services.getPricingModel({
      appId,
      languageCode,
      isPublished: false,
    });
    return new AppPricingPlans(undefined, pricingModel, status).toJSON();
  } else {
    const [appsPlans, pricingModel] = await Promise.all([
      services.queryAppsPlans({
        appIds: [appId],
      }),
      services.getPricingModel({
        appId,
        languageCode,
        isPublished: status === 'PUBLISHED',
      }),
    ]);

    return new AppPricingPlans(
      appsPlans.findByAppId(appId),
      pricingModel,
      status,
    ).toJSON();
  }
}
