import type { Prices, IAppPricingPlan } from './types';
import { PricingPlanType } from './types';
import type {
  MeterdBilling,
  IAppPlan,
  PlanPrice,
  Discount,
} from '../apps-plans';
import type { PlanDescription, IPricingModelPlan } from '../pricing-model';
import type { SupportedAppStatuses } from '../../types';

/**
 * Represents a unified class for managing app pricing plan .
 * Retrieves app plans from the app-plans service and pricing models from the pricing-proxy service.
 * @class
 */
export class AppPricingPlan {
  constructor(
    private readonly status: SupportedAppStatuses,
    private readonly pricingModelPlan: IPricingModelPlan,
    private readonly appPlan?: IAppPlan,
  ) {}

  get vendorId(): string {
    return this.pricingModelPlan.vendorId;
  }
  get name(): string {
    return this.pricingModelPlan.name;
  }

  get title(): string | undefined {
    return this.pricingModelPlan.title;
  }

  get isVisible(): boolean {
    return this.pricingModelPlan.isVisible;
  }

  get isFree(): boolean {
    return this.pricingModelPlan.isFree;
  }

  get description(): PlanDescription {
    return this.pricingModelPlan.description;
  }
  get meterdBilling(): MeterdBilling | undefined {
    if (this.status === 'DRAFT') {
      return this.pricingModelPlan.meterdBilling;
    }
    return this.appPlan?.meterdBilling;
  }
  get yearlyPrice(): PlanPrice | undefined {
    if (this.status === 'DRAFT') {
      return { price: this.pricingModelPlan.yearlyPrice };
    }
    return this.appPlan?.yearlyPrice;
  }
  get monthlyPrice(): PlanPrice | undefined {
    if (this.status === 'DRAFT') {
      return { price: this.pricingModelPlan.monthlyPrice };
    }
    return this.appPlan?.monthlyPrice;
  }

  get oneTimePrice(): PlanPrice | undefined {
    if (this.status === 'DRAFT') {
      return { price: this.pricingModelPlan.oneTimePrice };
    }
    return this.appPlan?.oneTimePrice;
  }

  get discount(): Discount | undefined {
    if (this.status === 'DRAFT') {
      return undefined;
    }
    return this.appPlan?.discount;
  }

  get type(): PricingPlanType {
    let planType = PricingPlanType.FREE;

    if (this.meterdBilling) {
      planType = PricingPlanType.METERED;
    } else if (this.oneTimePrice?.price && this.oneTimePrice.price !== '0') {
      planType = PricingPlanType.ONE_TIME;
    } else if (
      (this.yearlyPrice?.price && this.yearlyPrice.price !== '0') ||
      (this.monthlyPrice?.price && this.monthlyPrice.price !== '0')
    ) {
      planType = PricingPlanType.RECURRING;
    }
    return planType;
  }

  get prices(): Prices | undefined {
    switch (this.type) {
      case PricingPlanType.METERED:
        return {
          price: Number(this.meterdBilling?.baseFee),
          discountPrice: Number(this.meterdBilling?.discountBaseFee)
            ? Number(this.meterdBilling?.discountBaseFee)
            : undefined,
        };
      case PricingPlanType.ONE_TIME:
        return {
          price: Number(this.oneTimePrice?.price),
          discountPrice: Number(this.oneTimePrice?.discountPrice)
            ? Number(this.oneTimePrice?.discountPrice)
            : undefined,
        };
      case PricingPlanType.RECURRING:
        const discountPrice = Math.min(
          Number(this.monthlyPrice?.discountPrice || Infinity),
          Number(this.yearlyPrice?.discountPrice || Infinity),
        );
        return {
          price: Math.min(
            Number(this.monthlyPrice?.price),
            Number(this.yearlyPrice?.price || Infinity),
          ),
          discountPrice: discountPrice === Infinity ? undefined : discountPrice,
        };
      default:
        return undefined;
    }
  }

  toJSON(): IAppPricingPlan {
    return {
      vendorId: this.vendorId,
      name: this.name,
      title: this.title,
      isVisible: this.isVisible,
      isFree: this.isFree,
      description: this.description,
      meterdBilling: this.meterdBilling,
      yearlyPrice: this.yearlyPrice,
      monthlyPrice: this.monthlyPrice,
      oneTimePrice: this.oneTimePrice,
      discount: this.discount,
      type: this.type,
      prices: this.prices,
    };
  }
}
