import React from 'react';
import {
  BadgeType,
  AppBundleType,
} from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { AppBundle } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import { BundleBadge } from './bundle-badge';
import { WixChoiceBadge } from './wix-choice-badge';
import { BetaBadge } from './beta-badge';
import type { AppBadge } from '@wix/app-market-services';
import { useEssentials } from '../../contexts/essentials-context';
import AppBadges from './app-badges';

// TODO: remove irrelevant props and use appBadges only instead ater imgration
interface BadgesProps {
  badges?: BadgeType[];
  size?: 'tiny' | 'small' | 'medium';
  bundle?: AppBundle;
  isInstalled: boolean;
  appName: string;
  showBorder?: boolean;
  appBadges?: AppBadge[];
  showNewAppBadges?: boolean;
  wixSaleSize?: 'small' | 'big';
}

export function Badges({
  badges,
  size = 'tiny',
  bundle,
  isInstalled,
  appName,
  showBorder = false,
  appBadges = [],
  wixSaleSize = 'big',
}: BadgesProps): JSX.Element {
  const { experiments } = useEssentials();
  const showNovemberSaleBadge =
    experiments?.enabled('specs.app-market.Sale') ?? false;
  const showDeveloperSaleBadge =
    experiments?.enabled('specs.app-market.DeveloperSaleBadge') ?? false;
  const useNewAppBadgesService = experiments?.enabled(
    'specs.app-market.AppBadgesService',
  );

  const isWixChoice = badges?.some((type) => type === BadgeType.WIX_CHOICE);
  const isBeta = badges?.some((type) => type === BadgeType.BETA);

  if (useNewAppBadgesService) {
    return (
      <>
        <AppBadges
          {...{
            appBadges,
            appName,
            showBorder,
            isInstalled,
            size,
            showNovemberSaleBadge,
            showDeveloperSaleBadge,
            wixSaleSize,
          }}
        />
      </>
    );
  }
  return (
    <>
      {isWixChoice && <WixChoiceBadge {...{ showBorder, size }} />}
      {bundle?.isBundleApp && (
        <BundleBadge
          isCouponTypeBundle={bundle?.type === AppBundleType.COUPON}
          {...{ isInstalled, appName, showBorder, size }}
        />
      )}
      {isBeta && !isWixChoice && (
        <BetaBadge {...{ appName, showBorder, size }} />
      )}
    </>
  );
}
