import React from 'react';
import s from './rating-section.module.scss';
import {
  Box,
  Text,
  Heading,
  StarsRatingBar,
  LinearProgressBar,
} from '@wix/design-system';
import type { starRatingBarValue } from '@wix/design-system';
import { FavoriteFilled } from '@wix/wix-ui-icons-common';
import type { ReviewsSummary } from '../../../exported-components/rate-and-reviews-section/types';
import { useEssentials } from '../../../contexts/essentials-context';

interface RatingSectionProps {
  reviewsSummary: ReviewsSummary;
}

export function RatingSection({ reviewsSummary }: RatingSectionProps) {
  const { t } = useEssentials();
  const { averageRating, totalReviews, ratingHistogram } = reviewsSummary;
  return (
    <Box
      className={s.reviewsSummary}
      align="space-between"
      dataHook="rating-element"
      marginTop="6px"
    >
      <Box direction="vertical" flex={1}>
        <Box verticalAlign="middle">
          <FavoriteFilled color="#FDB10C" style={{ marginRight: '6px' }} />
          <Heading as="h2" size="large" dataHook="average-rating-heading">
            {t('app.page.reviews.averageRating', {
              averageRating: averageRating.toFixed(1),
            })}
          </Heading>
        </Box>
        <Box marginLeft="31px">
          <Text dataHook="reviews-number-subtitle">
            {totalReviews === 1
              ? t('app.page.reviews.one.review.subtitle')
              : t('app.page.reviews.subtitle', { totalReviews })}
          </Text>
        </Box>
      </Box>
      <Box direction="vertical" flex={1}>
        {[5, 4, 3, 2, 1].map((rate, index) => {
          const dynamicRating = `rating${rate}`;
          const ratePercentage = Math.ceil(
            (ratingHistogram[dynamicRating] / totalReviews) * 100,
          );
          return (
            <Box verticalAlign="middle" gap="0px" height="15px">
              <Text secondary size="tiny">
                {rate}
              </Text>
              <Box width="100px" align="center">
                <StarsRatingBar
                  value={rate as starRatingBarValue}
                  size="tiny"
                  readOnly
                ></StarsRatingBar>
              </Box>
              <LinearProgressBar
                dataHook={`linear-progress-bar-${index}`}
                skin="warning"
                value={ratePercentage}
              ></LinearProgressBar>
              <Heading
                dataHook={`linear-progress-bar-${index}-percentage`}
                size="extraTiny"
                style={{ paddingLeft: '10px' }}
              >
                {ratingHistogram[dynamicRating]}
              </Heading>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
