import type { PlanDescription } from '../pricing-model';
import type {
  TaxDetails,
  CurrencySettings,
  MeterdBilling,
  PlanPrice,
  Discount,
} from '../apps-plans';

export enum PricingPlanType {
  FREE = 'FREE',
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
  DYNAMIC = 'DYNAMIC',
  METERED = 'METERED',
}

export type Prices = {
  price: number;
  discountPrice?: number;
};

/**
 * Represents a unified type for managing app pricing.
 * @type
 */
export type IAppsPricingPlans = {
  taxSettings: TaxDetails;
  currency: CurrencySettings;
  appId: string;
  isExternalPricing: boolean;
  externalPricingPageUrl?: string;
  plans: IAppPricingPlan[];
  isFreeApp: boolean;
  hasFreePlan: boolean;
  hasDynamicPlan: boolean;
  hasMeteredPlanWithNoBaseFee: boolean;
  cheapestPlan:
    | {
        planType: PricingPlanType;
        prices: Prices;
      }
    | undefined;
};

/**
 * Represents a unified type for managing app pricing plan.
 * @type
 */
export type IAppPricingPlan = {
  vendorId: string;
  name: string;
  title?: string;
  isVisible: boolean;
  isFree: boolean;
  description: PlanDescription;
  meterdBilling?: MeterdBilling;
  yearlyPrice?: PlanPrice;
  monthlyPrice?: PlanPrice;
  oneTimePrice?: PlanPrice;
  discount?: Discount;
  type: PricingPlanType;
  prices?: Prices;
};
