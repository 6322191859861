import React, { useEffect, useRef } from 'react';
import {
  Box,
  SidebarDividerNext,
  SidebarItemNext,
  SidebarNext,
  SidebarSubMenuNext,
  SidebarTitleItemNext,
} from '@wix/design-system';
import { SidebarHeader } from '../sidebar-header';
import { SidebarFooter } from '../sidebar-footer';
import { useMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { config } from '../../config';
import { Origin } from '../../enums/marketplace-enums';
import { useConfigContext } from '../../config-context';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import type { AppMarketRpcClient } from '../../types/app-market-rpc-client';
import { biLogger } from '../../bi';
import {
  appMarketMenuNavigation,
  appMarketSubCategoryClick,
} from '@wix/bi-logger-app-market-data/v2';
import { RoutePath, useRouterContext, Route } from '@wix/app-market-core';
import type { SidebarItem, SidebarLink, SidebarSubMenu } from './sidebar.utils';
import {
  determineSelectedItemKey,
  getCategories,
  getContainer,
  getHref,
  getPlatform,
  getRoute,
  getSidebarDealerSections,
  mapSidebarSectionToSidebarItem,
  reduceSectionsToSidebarItems,
} from './sidebar.utils';
import s from './sidebar.scss';
import type { DynamicSection } from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import type { IRoute } from '../../types/common/marketplace';

async function fetchSidebarItems(
  platformName: string,
  languageCode: string,
  t: any,
  experiments: InjectedExperimentsProps['experiments'],
  getAppInstance?: (appDefID: string) => string,
  privateApp?: PrivateApp,
  rpcClient?: AppMarketRpcClient,
): Promise<SidebarItem[]> {
  const showSidebarOverDealer =
    experiments?.enabled('specs.marketplace.SidebarOverDealer') &&
    config.origin !== Origin.STANDALONE;

  const [getContainerResponse, getCategoriesResponse, sidebarDealerSections] =
    await Promise.all([
      getContainer(platformName, 'menu', languageCode, rpcClient),
      getCategories(languageCode, rpcClient),
      showSidebarOverDealer
        ? getSidebarDealerSections({ rpcClient, getAppInstance })
        : ([] as DynamicSection[]),
    ]);

  const HOME_PAGE_LINK: SidebarLink = {
    type: 'link',
    text: t('menu.home'),
    slug: 'home',
    pageType: 'home',
  };
  const initialElements: SidebarItem[] = [HOME_PAGE_LINK];
  const showPrivateAppsItem =
    privateApp.tagId && privateApp.tagName && privateApp.tagSlug;

  if (showPrivateAppsItem) {
    const PRIVATE_APPS_LINK: SidebarLink = {
      type: 'link',
      text: t('home.privateApps.title', { accountName: privateApp.tagName }),
      slug: privateApp.tagSlug,
      pageType: 'collection',
    };
    initialElements.push(PRIVATE_APPS_LINK);
  }

  if (showSidebarOverDealer) {
    const sidebarItemsDealer = sidebarDealerSections.map(
      mapSidebarSectionToSidebarItem,
    );
    return initialElements.concat(sidebarItemsDealer);
  }

  const sidebarItems = getContainerResponse.container.sections.reduce(
    (accumulatedItems, section) =>
      reduceSectionsToSidebarItems(
        accumulatedItems,
        section,
        getCategoriesResponse,
      ),
    initialElements,
  );

  return sidebarItems;
}

type SidebarContainerProps = {
  experiments: InjectedExperimentsProps['experiments'];
  t: any;
};

interface PrivateApp {
  tagId?: string;
  tagName?: string;
  tagSlug?: string;
}

function SidebarContainer({ experiments, t }: SidebarContainerProps) {
  const {
    locale: languageCode,
    rpcClient,
    getAppInstance,
  } = useConfigContext();
  const { route, privateAppsTagId, privateAppsTagName, privateAppsTagSlug } =
    useMarketplaceStore();
  const platformName = getPlatform(route);
  const isFirstRenderRef = useRef<boolean>(true);
  const refetchRef = useRef<any>(() => {});
  const privateApps: PrivateApp = {
    tagId: privateAppsTagId,
    tagName: privateAppsTagName,
    tagSlug: privateAppsTagSlug,
  };

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      refetchRef.current(() =>
        fetchSidebarItems(
          platformName,
          languageCode,
          t,
          experiments,
          getAppInstance,
          privateApps,
        ),
      );
    }

    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
    }
  }, [platformName, languageCode, privateApps]);

  return (
    <WithFetcher
      loader={null}
      fetchMemoizationId={`sidebar-${languageCode}`}
      initialFetch={() =>
        fetchSidebarItems(
          platformName,
          languageCode,
          t,
          experiments,
          getAppInstance,
          privateApps,
          rpcClient,
        )
      }
    >
      {({ fetchData: sidebarItems, reFetch }) => {
        refetchRef.current = reFetch;

        return (
          <Sidebar sidebarItems={sidebarItems} experiments={experiments} />
        );
      }}
    </WithFetcher>
  );
}

interface SidebarProps {
  experiments: InjectedExperimentsProps['experiments'];
  sidebarItems: SidebarItem[];
}

function Sidebar({ experiments, sidebarItems }: SidebarProps) {
  const isInStandaloneMarket = config.origin === Origin.STANDALONE;
  const { metaSiteId } = useMarketplaceStore();
  let { route } = useMarketplaceStore();
  const { route: newRoute } = useRouterContext();
  if (newRoute.path !== RoutePath.UNSUPPORTED) {
    const rClass = new Route({ ...newRoute, baseURL: undefined });
    route = rClass.toOldRoute() as IRoute;
  }
  const [selectedSidebarItemKey, setSelectedSidebarItemKey] =
    React.useState<string>(() => {
      return determineSelectedItemKey(route);
    });

  useEffect(() => {
    setSelectedSidebarItemKey(determineSelectedItemKey(route));
  }, [route]);

  function navigateToSidebarItem(sidebarItem: SidebarLink | SidebarSubMenu) {
    if (selectedSidebarItemKey === sidebarItem.slug) {
      return;
    }

    setSelectedSidebarItemKey(sidebarItem.slug);
    setTimeout(() => {
      /*
       * this timeout is a hack to fix a bug in editorX and Studio
       * the bug is that the popover of the subcategories of a certain category remains open
       */
      config.goto(getRoute(sidebarItem));
    }, 50);
  }

  return (
    <Box zIndex={10} className={s.sidebar}>
      <SidebarNext
        skin="light"
        selectedKey={selectedSidebarItemKey}
        isLoading={sidebarItems.length === 0}
        header={<SidebarHeader experiments={experiments} />}
        footer={<SidebarFooter experiments={experiments} />}
      >
        {sidebarItems.map((sidebarItem, index) => {
          if (sidebarItem.type === 'divider') {
            return <SidebarDividerNext key={`divider-${index}`} />;
          }

          if (sidebarItem.type === 'title') {
            return (
              <SidebarTitleItemNext key={sidebarItem.text}>
                {sidebarItem.text}
              </SidebarTitleItemNext>
            );
          }

          if (sidebarItem.type === 'link') {
            return (
              <SidebarItemNext
                dataHook={`sidebar-collection-item-${sidebarItem.slug}`}
                key={sidebarItem.slug}
                itemKey={sidebarItem.slug}
                as="a"
                href={isInStandaloneMarket ? getHref(sidebarItem) : undefined}
                onClick={(event) => {
                  event.preventDefault();
                  biLogger.report(
                    appMarketMenuNavigation({
                      menu_item_label: sidebarItem.text,
                    }),
                  );
                  navigateToSidebarItem(sidebarItem);
                }}
              >
                {sidebarItem.text}
              </SidebarItemNext>
            );
          }

          if (sidebarItem.type === 'sub-menu') {
            return (
              <SidebarSubMenuNext
                dataHook={`sidebar-category-item-${sidebarItem.slug}`}
                key={sidebarItem.slug}
                itemKey={sidebarItem.slug}
                title={sidebarItem.title}
                as="a"
                href={isInStandaloneMarket ? getHref(sidebarItem) : undefined}
                onClick={(event) => {
                  event.preventDefault();
                  biLogger.report(
                    appMarketMenuNavigation({
                      menu_item_label: sidebarItem.title,
                    }),
                  );
                  navigateToSidebarItem(sidebarItem);
                }}
              >
                {sidebarItem.links.map((link) => (
                  <SidebarItemNext
                    dataHook={`sidebar-subcategory-item-${link.slug}`}
                    key={link.slug}
                    itemKey={link.slug}
                    as="a"
                    href={isInStandaloneMarket ? getHref(link) : undefined}
                    onClick={(event) => {
                      event.preventDefault();
                      biLogger.report(
                        appMarketSubCategoryClick({
                          msid: metaSiteId,
                          category_name: sidebarItem.title.toLowerCase(),
                          subcategory_name: link.slug
                            .toLowerCase()
                            .replace(/-/g, '_'),
                          type: 'sidebar',
                        }),
                      );
                      biLogger.report(
                        appMarketMenuNavigation({
                          menu_item_label: link.text,
                        }),
                      );
                      navigateToSidebarItem(link);
                    }}
                  >
                    {link.text}
                  </SidebarItemNext>
                ))}
              </SidebarSubMenuNext>
            );
          }

          return null;
        })}
      </SidebarNext>
    </Box>
  );
}

export { SidebarContainer as Sidebar, SidebarContainerProps as SidebarProps };
