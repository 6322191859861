import React from 'react';
import { DeveloperSaleBadge } from './dev-sale-badge';
import { WixSaleBadge } from './wix-sale-badge';
import { BetaBadge } from './beta-badge';
import { WixChoiceBadge } from './wix-choice-badge';
import { BundleBadge } from './bundle-badge';
import { AppBadgesType, BundleAppType } from '@wix/app-market-services';
import type { AppBadge } from '@wix/app-market-services';

interface AppBadgesProps {
  appBadges: AppBadge[];
  appName: string;
  showBorder: boolean;
  isInstalled: boolean;
  size: 'tiny' | 'small' | 'medium';
  showNovemberSaleBadge: boolean;
  showDeveloperSaleBadge: boolean;
  wixSaleSize?: 'small' | 'big';
}

export function AppBadges({
  appBadges,
  appName,
  showBorder,
  isInstalled,
  size,
  showNovemberSaleBadge,
  showDeveloperSaleBadge,
  wixSaleSize,
}: AppBadgesProps) {
  return (
    <>
      {appBadges.map(({ badge, bundleAppBadgePayload, saleBadgePayload }) => {
        switch (badge) {
          case AppBadgesType.BETA:
            return <BetaBadge {...{ appName, showBorder, size }} />;
          case AppBadgesType.WIX_CHOICE:
            return <WixChoiceBadge {...{ showBorder, size }} />;
          case AppBadgesType.PREMIUM_BENEFIT:
            return (
              <BundleBadge
                isCouponTypeBundle={
                  bundleAppBadgePayload?.appBundleType === BundleAppType.COUPON
                }
                {...{ appName, showBorder, size, isInstalled }}
              />
            );
          case AppBadgesType.WIX_SALE:
            if (showNovemberSaleBadge) {
              return (
                <WixSaleBadge
                  amount={saleBadgePayload?.amount!}
                  size={wixSaleSize}
                />
              );
            }
            return undefined;
          case AppBadgesType.DEV_SALE:
            if (showDeveloperSaleBadge) {
              return (
                <DeveloperSaleBadge
                  amount={saleBadgePayload?.amount!}
                  {...{ showBorder, size }}
                />
              );
            }
            return undefined;
          default:
            return undefined;
        }
      })}
    </>
  );
}

export default AppBadges;
