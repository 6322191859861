import type { AppGroupSection } from '@wix/app-market-services';
import {
  Box,
  FormField,
  SkeletonGroup,
  SkeletonLine,
  Text,
  ToggleSwitch,
} from '@wix/design-system';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { AppsSection as AppsSectionComponent } from '@wix/app-market-components';
import type { AppMarketPage } from '../../enums';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import { useQueryClient } from '@tanstack/react-query';
import { useEssentials, useRouterContext } from '../../contexts';
import { useTranslation } from '../../contexts/translations-context';
import { appMarketClickOnSaleFilterSrc24Evid167 } from '@wix/bi-logger-app-market-data/v2';
import { fetchWithQuery } from '../../decorators';
import type { FilterablePayload, QueryApps, QueryAppsResponse } from './types';
import { AppsSkeleton, LoadMoreAppsSkeletons } from './apps-section-skeletons';
import { queryKeyBuilder } from './app-section-helper';
import type { IRoute } from '../../models';

interface AppsSectionProps {
  initData: QueryAppsResponse | null;
  queryApps: QueryApps;
  origin: AppMarketPage;
  queryId: string;
  showBorder?: boolean;
  isLoading?: boolean;
  emptyState?: React.JSX.Element;
  showSaleToggle?: boolean;
}

export function AppsSection({
  queryApps,
  emptyState,
  origin,
  queryId,
  initData,
  isLoading,
  showBorder = true,
  showSaleToggle = true,
}: AppsSectionProps) {
  const queryClient = useQueryClient();
  const { i18n, experiments, biLogger } = useEssentials();
  const { router, route } = useRouterContext();
  const { t } = useTranslation();

  const [pageData, setPageData] = useState<QueryAppsResponse | null>(initData);
  const [skeleton, setSkeleton] = useState<'full-page' | 'load-more' | null>(
    'full-page',
  );
  const [saleToggle, setSaleToggle] = useState<boolean>(
    !!(route.payload as FilterablePayload).isSale,
  );
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();

  const shouldShowSaleToggle =
    experiments.enabled('specs.app-market.SaleToggle') && showSaleToggle;

  function reportToggleClick(value: boolean) {
    biLogger.report(
      appMarketClickOnSaleFilterSrc24Evid167({
        filter_value: `${value}`,
        tag_type: origin,
        tag_name: origin,
      }),
    );
  }

  async function fetchMoreApps(isSale: boolean) {
    setSkeleton('load-more');
    const queryKey = queryKeyBuilder({
      queryName: 'fetch-more-apps',
      language: i18n.language,
      appsLength: pageData?.appGroup.apps.length ?? 0,
      isSale,
      queryId,
    });
    try {
      const _pageData = await fetchWithQuery({
        queryClient,
        queryFn: () =>
          queryApps({
            filter: { isSale },
            offset: pageData?.appGroup.apps.length,
          }),
        queryKey,
      });
      updatePageData(_pageData);
    } catch (error) {
      console.log(`[apps-section] (fetchMoreApps) Error:`, error);
    } finally {
      setSkeleton(null);
    }
  }

  useEffect(() => {
    if (isLoading) {
      setSkeleton('full-page');
    } else {
      setPageData(initData);
      setSkeleton(null);
    }
  }, [isLoading, initData]);

  function updatePageData(_pageData: QueryAppsResponse | null) {
    if (_pageData) {
      const { appGroup, paging } = _pageData;
      setPageData((prev) =>
        prev
          ? {
              ...prev,
              appGroup: {
                ...prev.appGroup,
                apps: [...(prev.appGroup.apps || []), ...(appGroup.apps || [])],
              },
              paging: {
                ...prev.paging,
                hasNext: paging.hasNext,
              },
            }
          : prev,
      );
    } else {
      setPageData(null);
    }
  }

  function AppsCount() {
    return (
      <Box alignSelf="center">
        {skeleton === 'full-page' ? (
          <SkeletonGroup>
            <SkeletonLine width="48px" />
          </SkeletonGroup>
        ) : (
          pageData && (
            <Text weight="normal" size="small" secondary>
              {t('collection.apps-counter', {
                appsCount: pageData.paging.total,
              })}
            </Text>
          )
        )}
      </Box>
    );
  }

  function FilterToggle() {
    return (
      <FormField
        stretchContent={false}
        label={t('collection.sale-toggle')}
        labelPlacement="left"
      >
        <ToggleSwitch
          checked={saleToggle}
          onChange={() => {
            setSaleToggle(!saleToggle);
            reportToggleClick(!saleToggle);
            router.navigateTo({
              path: route.path,
              payload: {
                ...route.payload,
                isSale: !saleToggle,
              },
            } as IRoute);
          }}
        />
      </FormField>
    );
  }

  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && pageData?.paging.hasNext && !skeleton) {
        fetchMoreApps(saleToggle);
      }
    },
    [saleToggle, pageData?.appGroup.apps, skeleton],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => observer.disconnect();
  }, [handleObserver]);

  function AppsSectionContainer() {
    return (
      <>
        <Box direction="vertical">
          {skeleton === 'full-page' ? (
            <AppsSkeleton />
          ) : pageData && pageData.appGroup.apps.length > 0 ? (
            <AppsSectionComponent
              showBorder={showBorder}
              {...appsSectionSharedPropsMapper(
                {
                  ...pageData.appGroup,
                  apps: pageData.appGroup.apps as AppGroupSection['apps'],
                },
                origin,
                origin,
                0,
              )}
            />
          ) : (
            <Box marginTop="18px" direction="vertical">
              {emptyState}
            </Box>
          )}
        </Box>
        {skeleton === 'load-more' && <LoadMoreAppsSkeletons />}
        <div
          data-hook="ref-div"
          ref={loadMoreRef}
          style={{
            marginTop: '-600px',
          }}
        />
      </>
    );
  }

  return (
    <Box direction="vertical" marginTop="12px" dataHook="apps-section">
      <Box
        display="flex"
        marginBottom="-4px"
        verticalAlign="space-between"
        height={24}
      >
        <AppsCount />
        {shouldShowSaleToggle && <FilterToggle />}
      </Box>
      <AppsSectionContainer />
    </Box>
  );
}
