import React from 'react';
import type { ButtonSize } from '@wix/design-system';
import { Button, Loader, Tooltip } from '@wix/design-system';
import {
  addAppClick,
  appMarketAppInstallationFailedClient,
  appMarketAppInstallationSuccess,
} from '@wix/bi-logger-app-market-data/v2';

import { useTranslation } from '../../../../contexts/translations-context';
import { InstallAppButtonDataHooks } from './data-hooks';
import {
  useEssentials,
  useInstaller,
  useJunkYard,
  useServices,
  useUserContext,
} from '../../../../contexts';
import {
  getAddAppClickBiData,
  getAppMarketAppInstallationFailedClientBiData,
  getAppMarketAppInstallationSuccessBiData,
} from '../../../../bi-services/events-data-parser';
import type { AppBadge, BadgeType } from '@wix/app-market-services';
import { useQueryClient } from '@tanstack/react-query';
import { QueryDecorator } from '../../../../decorators';

export interface InstallAppButtonProps {
  origin: string;
  isDisabled: boolean;
  tooltipContent: string;
  app: {
    id: string;
    isBundle: boolean;
    badges?: BadgeType[];
    appBadges?: AppBadge[];
  };
  size: ButtonSize;
  openMobileInstallationBlockedModal: () => void;
}

export function InstallAppButton({
  origin,
  isDisabled,
  tooltipContent,
  app,
  size,
  openMobileInstallationBlockedModal,
}: InstallAppButtonProps) {
  const { t } = useTranslation();
  const { isMobile } = useUserContext();
  const installer = useInstaller();
  const queryClient = useQueryClient();
  const services = useServices();
  const { biLogger } = useEssentials();
  const { routerData, goBackToReferrer } = useJunkYard();

  return (
    <QueryDecorator
      queryFn={async () => {
        const webSolutionsBase = await services.getWebSolutionsBase({
          appIds: [app.id],
        });
        const webSolutionBase = webSolutionsBase.findByAppId(app.id);
        return webSolutionBase?.openConsentMethod;
      }}
      queryKey={`install-app-button-${app.id}`}
      csrOnly
    >
      {({ data: openConsentMethod, isLoading }) => {
        return (
          <Tooltip
            dataHook={InstallAppButtonDataHooks.tooltip}
            inline
            disabled={!tooltipContent}
            content={tooltipContent}
          >
            <Button
              dataHook={InstallAppButtonDataHooks.button}
              disabled={isDisabled || isLoading}
              size={size}
              onClick={() => {
                biLogger.report(
                  addAppClick(
                    getAddAppClickBiData({
                      appId: app.id,
                      isBundleApp: app.isBundle,
                      tagType: 'app_page',
                      tagName: app.id,
                      routerData,
                      referrerNameForBI: goBackToReferrer?.referrerNameForBI,
                      badges: app.badges,
                      appBadges: app.appBadges,
                      origin,
                    }),
                  ),
                );

                if (isMobile) {
                  return openMobileInstallationBlockedModal();
                }

                installer.installApp({
                  appId: app.id,
                  consentOpeningMethod: openConsentMethod ?? 'NEW_TAB',
                  onSuccess: (instanceId) => {
                    queryClient.invalidateQueries();
                    biLogger.report(
                      appMarketAppInstallationSuccess(
                        getAppMarketAppInstallationSuccessBiData({
                          appId: app.id,
                          tagType: 'app_page',
                          tagName: app.id,
                          instanceId,
                          routerData,
                          referrerNameForBI:
                            goBackToReferrer?.referrerNameForBI,
                          isBundleApp: app.isBundle,
                          badges: app.badges,
                          appBadges: app.appBadges,
                          origin,
                        }),
                      ),
                    );
                  },
                  onFailure: () => {
                    biLogger.report(
                      appMarketAppInstallationFailedClient(
                        getAppMarketAppInstallationFailedClientBiData({
                          appId: app.id,
                          tagType: 'app_page',
                          tagName: app.id,
                        }),
                      ),
                    );
                  },
                });
              }}
            >
              {isLoading ? (
                <Loader size="tiny" />
              ) : (
                t('solution.page.addToSite')
              )}
            </Button>
          </Tooltip>
        );
      }}
    </QueryDecorator>
  );
}
