import {
  isBundleAppAndInstalled,
  isBundleAppAndNotInstalled,
} from '../../common/utils';
import type { GUID } from '@wix/bi-logger-app-market-data/v2/types';
import type { IBiData, IRoute } from '../../types/common/marketplace';
import { getReferralInfo, getRouteForBi } from '../../common/bi-events';
import type {
  BundleWebSolution,
  ApiTag,
} from '@wix/ambassador-marketplace/types';
import type { WebSolutionExtended } from '@wix/app-market-collection-widget';

export const getWebSolutionsBiData = (
  solutions: WebSolutionExtended[],
  impressionType: string,
  tagName: string,
  msid: GUID,
  route: IRoute,
  prevRoute: IRoute,
  bundleApps: BundleWebSolution[],
  collimp_id?: GUID,
  section?: string,
): IBiData[] => {
  if (solutions) {
    const { market, subCategory } = getRouteForBi(route, prevRoute);
    return solutions.map(
      (solution: WebSolutionExtended, index: number): IBiData => {
        return {
          msid,
          appId: solution?.id,
          impressionType,
          index,
          market,
          tagName: tagName.replace(/-/g, '_'),
          tagType: 'homepage',
          subCategory,
          section: section || tagName.replace(/-/g, '_'),
          referralInfo: getReferralInfo(route.referral, prevRoute),
          searchTerm: route.query ? route.query : prevRoute && prevRoute.query,
          isBundleAppNotInstalled: isBundleAppAndNotInstalled(
            solution?.id,
            bundleApps,
          ),
          isBundleAppInstalled: isBundleAppAndInstalled(
            solution?.id,
            bundleApps,
          ),
          collimp_id,
          referralTag: route?.referralTag,
        };
      },
    );
  }
  return [];
};

export const getTagBiData = (
  tags: ApiTag[],
  tagName: string,
  tagTypeClicked: string,
  widgetType: string,
  msid: GUID,
  route: IRoute,
  prevRoute: IRoute,
): {
  widgetType?: string;
  index?: number;
  market?: string;
  msid?: GUID;
  tagName?: string;
  tagNameClicked?: string;
  tagType?: string;
  tagTypeClicked?: string;
  section?: string;
}[] => {
  if (tags) {
    const { market } = getRouteForBi(route, prevRoute);
    return tags.map((tag: ApiTag, index: number) => {
      return {
        index,
        msid,
        market,
        widgetType,
        tagName: tagName.replace(/-/g, '_'),
        tagNameClicked: tag?.slug.replace(/-/g, '_'),
        tagType: 'homepage',
        tagTypeClicked,
      };
    });
  }
};
