import React, { useEffect } from 'react';
import { Page } from '@wix/design-system';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
  useServices,
} from '../../contexts';
import { QueryDecorator } from '../../decorators';
import type { SearchResultsPageData } from './search-results-page-data';
import { AppMarketPage } from '../../enums';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import {
  appMarketPageView,
  appMarketPageStartLoadedSrc24Evid163,
} from '@wix/bi-logger-app-market-data/v2';
import { SearchResultsSkeleton } from './search-results-skeleton';
import { Header } from './header';
import s from '../pages.scss';
import { withSaleBanner } from '../../components/sale-banner';
import { SearchResultsComponent } from './search-results-component';
import { getSearchResultsPageData } from './search-results-page-data';
import { queryKeyBuilder } from '../../components/apps-section/app-section-helper';
import type { RoutePath, RoutePayloadMap } from '../../models';

function SearchResultsPageComponent({
  searchTerm,
  appGroup,
  paging,
}: SearchResultsPageData) {
  const pageName = AppMarketPage.SEARCH_RESULT;
  const { routerData } = useJunkYard();
  const { biLogger } = useEssentials();
  const isAppsFound = paging.total > 0;

  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          urlParams,
          pageType: pageName,
          pageName: searchTerm,
        }),
      ),
    );
  }, []);

  return (
    <Page className={s.appMarketPage}>
      <Page.Content>
        <Header {...{ isAppsFound, searchTerm }} />
        <SearchResultsComponent
          searchTerm={searchTerm}
          initData={{ paging, appGroup }}
        />
      </Page.Content>
    </Page>
  );
}

export const SearchResultsPage = withSaleBanner(function ({
  searchTerm,
}: {
  searchTerm: string;
}) {
  const { biLogger, i18n } = useEssentials();
  const { route } = useRouterContext();
  const services = useServices();
  const isSale = Boolean(
    (route.payload as RoutePayloadMap[RoutePath.SEARCH_RESULTS]).isSale,
  );

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.SEARCH_RESULT,
      }),
    );
  }, [searchTerm]);

  return (
    <QueryDecorator
      queryFn={() =>
        getSearchResultsPageData({
          searchTerm,
          services,
          isSale,
        })
      }
      queryKey={queryKeyBuilder({
        queryName: 'initial-apps-fetch',
        language: i18n.language,
        appsLength: 0,
        isSale,
        queryId: searchTerm,
      })}
    >
      {({ data, isLoading }) =>
        isLoading ? (
          <SearchResultsSkeleton />
        ) : (
          <SearchResultsPageComponent
            searchTerm={searchTerm}
            appGroup={data.appGroup}
            paging={data.paging}
          />
        )
      }
    </QueryDecorator>
  );
});
