import type { SupportedAppStatuses } from '@wix/app-market-services';

enum RoutePath {
  APP_DASHBOARD = 'APP_DASHBOARD',
  APP_EXTERNAL_DASHBOARD = 'APP_EXTERNAL_DASHBOARD',
  BACK = 'BACK',
  EDITOR_COMPLETE_APP_SETUP = 'EDITOR_COMPLETE_APP_SETUP',
  WIX_OFFERING = 'WIX_OFFERING',
  APP_PAGE = 'APP_PAGE',
  HOME = 'HOME',
  COLLECTION = 'COLLECTION',
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  SEARCH_RESULTS = 'SEARCH_RESULTS',
  SHARE = 'SHARE',
  DEVELOPER = 'DEVELOPER',
  FINISH_SETUP = 'FINISH_SETUP',
  FINISH_UPDATE = 'FINISH_UPDATE',
  UNSUPPORTED = 'UNSUPPORTED',
  MANAGE_APPS = 'MANAGE_APPS',
  OPEN_APP_WITH_PARAMS = 'OPEN_APP_WITH_PARAMS',
}

interface BaseRoutePayload {
  referral?: string;
  hideMenu?: boolean;
  referralSectionName?: string;
}

// TODO: complete type
interface HomeRoutePayload extends BaseRoutePayload {}

interface CollectionRoutePayload extends BaseRoutePayload {
  slug: string;
  isSale?: boolean;
}

interface CategoryRoutePayload extends BaseRoutePayload {
  slug: string;
}

interface SubCategoryRoutePayload extends BaseRoutePayload {
  parentSlug: string;
  slug: string;
  subCat: string;
  isSale?: boolean;
}

interface SearchResultsRoutePayload extends BaseRoutePayload {
  query: string;
  isSale?: boolean;
}

interface AppDashboardRoutePayload extends BaseRoutePayload {
  appId: string;
}

interface AppExternalDashboardRoutePayload extends BaseRoutePayload {
  appId: string;
  url: string;
}

interface EditorCompleteAppSetupRoutePayload extends BaseRoutePayload {
  appId: string;
}

interface WixOfferingRoutePayload extends BaseRoutePayload {
  appId: string;
}

interface ShareUrlRoutePayload extends BaseRoutePayload {
  appId: string;
  shareId: string;
  version?: string;
}
interface DeveloperPageRoutePayload extends BaseRoutePayload {
  slug: string;
}

interface FinishSetupRoutePayload extends BaseRoutePayload {
  appId: string;
  version: string;
}
interface FinishUpdateRoutePayload extends BaseRoutePayload {
  appId: string;
  version: string;
}

interface AppPageRoutePayload extends BaseRoutePayload {
  appId?: string;
  slug?: string;
  collimp_id?: string;
  appIndex?: string;
  status?: SupportedAppStatuses;
  searchLocation?: string;
  version?: string;
}

interface IManageAppsDeepLinkData extends BaseRoutePayload {
  appId: string;
  modal: string;
}

interface ManageAppsRoutePayload extends BaseRoutePayload {
  manageAppsDeepLinkData: IManageAppsDeepLinkData;
}

interface OpenAppWithParamsRoutePayload extends BaseRoutePayload {
  appId: string;
  version: string;
  openAppOptions: any;
}

interface RoutePayloadMap {
  [RoutePath.HOME]: HomeRoutePayload;
  [RoutePath.COLLECTION]: CollectionRoutePayload;
  [RoutePath.CATEGORY]: CategoryRoutePayload;
  [RoutePath.SUB_CATEGORY]: SubCategoryRoutePayload;
  [RoutePath.SEARCH_RESULTS]: SearchResultsRoutePayload;
  [RoutePath.APP_DASHBOARD]: AppDashboardRoutePayload;
  [RoutePath.APP_EXTERNAL_DASHBOARD]: AppExternalDashboardRoutePayload;
  [RoutePath.BACK]: undefined;
  [RoutePath.EDITOR_COMPLETE_APP_SETUP]: EditorCompleteAppSetupRoutePayload;
  [RoutePath.WIX_OFFERING]: WixOfferingRoutePayload;
  [RoutePath.APP_PAGE]: AppPageRoutePayload;
  [RoutePath.SHARE]: ShareUrlRoutePayload;
  [RoutePath.DEVELOPER]: DeveloperPageRoutePayload;
  [RoutePath.FINISH_SETUP]: FinishSetupRoutePayload;
  [RoutePath.FINISH_UPDATE]: FinishUpdateRoutePayload;
  [RoutePath.MANAGE_APPS]: ManageAppsRoutePayload | undefined;
  [RoutePath.OPEN_APP_WITH_PARAMS]: OpenAppWithParamsRoutePayload;
  [RoutePath.UNSUPPORTED]: undefined;
}

type SomeRoute<T extends RoutePath> = {
  path: T;
  payload: RoutePayloadMap[T];
};

type IRoute =
  | SomeRoute<RoutePath.HOME>
  | SomeRoute<RoutePath.COLLECTION>
  | SomeRoute<RoutePath.CATEGORY>
  | SomeRoute<RoutePath.SUB_CATEGORY>
  | SomeRoute<RoutePath.SEARCH_RESULTS>
  | SomeRoute<RoutePath.APP_DASHBOARD>
  | SomeRoute<RoutePath.APP_EXTERNAL_DASHBOARD>
  | SomeRoute<RoutePath.BACK>
  | SomeRoute<RoutePath.EDITOR_COMPLETE_APP_SETUP>
  | SomeRoute<RoutePath.WIX_OFFERING>
  | SomeRoute<RoutePath.APP_PAGE>
  | SomeRoute<RoutePath.SHARE>
  | SomeRoute<RoutePath.DEVELOPER>
  | SomeRoute<RoutePath.FINISH_SETUP>
  | SomeRoute<RoutePath.FINISH_UPDATE>
  | SomeRoute<RoutePath.MANAGE_APPS>
  | SomeRoute<RoutePath.OPEN_APP_WITH_PARAMS>
  | SomeRoute<RoutePath.UNSUPPORTED>;

export { IRoute, RoutePath, RoutePayloadMap };
