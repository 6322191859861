import { SortType, AppGroupType, APPS_LIMIT } from '@wix/app-market-services';
import type {
  AppGroupSection,
  IServices,
  QueryAppsFilter,
} from '@wix/app-market-services';
import type { QueryAppsResponse } from '../../components/apps-section';
import type Experiments from '@wix/wix-experiments';

export interface CollectionPageHeaderProps {
  slug: string;
  primaryTitle: string;
  secondaryTitle: string;
  description: string;
}

export async function getCollectionPageHeaderData({
  services,
  slug,
  languageCode,
}: {
  services: IServices;
  slug: string;
  languageCode: string;
}): Promise<CollectionPageHeaderProps> {
  const tags = await services.queryTag({ slugs: [slug], languageCode });
  const tag = tags.findBySlug(slug);
  if (tag.id === '') {
    throw new Error(
      `Collection with slug ${slug} not found, languageCode: ${languageCode}`,
    );
  }

  return {
    slug,
    primaryTitle: tag.name,
    secondaryTitle: tag.collectionTeaser,
    description: tag.description,
  };
}

export async function queryApps({
  services,
  experiments,
  slug,
  languageCode,
  offset = 0,
  filter,
}: {
  services: IServices;
  experiments: Experiments;
  slug: string;
  languageCode: string;
  offset?: number;
  filter?: QueryAppsFilter;
}): Promise<QueryAppsResponse> {
  const tags = await services.queryTag({ slugs: [slug], languageCode });
  const tag = tags.findBySlug(slug);
  if (tag.id === '') {
    throw new Error(
      `Collection with slug ${slug} not found, languageCode: ${languageCode}`,
    );
  }

  const tagId = tag.id;
  const shouldUseDealerSorting = experiments.enabled(
    'specs.app-market.useDealerSortingInAppsTag',
  );

  const { apps, hasNext, total } = await services.getAppsDataByTagId({
    tagId,
    sortTypes: shouldUseDealerSorting
      ? [SortType.DEALER]
      : [SortType.COUNTRY_CODE, SortType.APP_MARKET_SCORE],
    limit: APPS_LIMIT,
    status: 'PUBLISHED',
    offset,
    filter,
  });

  return {
    appGroup: {
      type: AppGroupType.COLLECTION,
      tagSlug: slug,
      apps: apps as AppGroupSection['apps'],
      title: '',
      totalAppsCount: 0,
    },
    paging: {
      total,
      hasNext,
    },
  };
}
