import type { IAppPlans } from '../apps-plans';
import {
  mockTaxSettings,
  mockCurrencySettings,
  SaleType,
  DiscountType,
} from '../apps-plans';
import type { IPricingModel } from '../pricing-model';
import { EPlanDescription } from '../pricing-model';

export const emptyAppPlansMock: IAppPlans = {
  appId: '123456',
  taxSettings: mockTaxSettings,
  currency: mockCurrencySettings,
  plans: [],
  isExternalBilling: false,
};

export const emptyPricingModelMock: IPricingModel = {
  appId: '123456',
  freeTrialDays: 0,
  isExternalPricing: false,
  plans: [],
  hasDynamicPlan: false,
};

export const mockDiscountCheapest: IAppPlans = {
  appId: '1234567',
  taxSettings: mockTaxSettings,
  currency: mockCurrencySettings,
  plans: [
    {
      vendorId: '1',
      monthlyPrice: {
        price: '90',
        discountPrice: '45',
      },
      yearlyPrice: {
        price: '100',
        discountPrice: '50',
      },
      discount: {
        amount: '50',
        type: DiscountType.PERCENTAGE,
        saleType: SaleType.NOVEMBER_SALE,
      },
    },
    {
      vendorId: '2',
      monthlyPrice: {
        price: '20',
        discountPrice: '10',
      },
      yearlyPrice: {
        price: '16',
        discountPrice: '8',
      },
      discount: {
        amount: '50',
        type: DiscountType.PERCENTAGE,
        saleType: SaleType.NOVEMBER_SALE,
      },
    },
    {
      vendorId: '3',
      monthlyPrice: {
        price: '25',
      },
      yearlyPrice: {
        price: '20',
      },
    },
  ],
  isExternalBilling: true,
};

export const mockNoYearlyPrices: IAppPlans = {
  appId: '1234567',
  taxSettings: mockTaxSettings,
  currency: mockCurrencySettings,
  plans: [
    {
      vendorId: '1',
      monthlyPrice: {
        price: '90',
        discountPrice: '45',
      },
      discount: {
        amount: '50',
        type: DiscountType.PERCENTAGE,
        saleType: SaleType.NOVEMBER_SALE,
      },
    },
    {
      vendorId: '2',
      monthlyPrice: {
        price: '20',
        discountPrice: '10',
      },
      discount: {
        amount: '50',
        type: DiscountType.PERCENTAGE,
        saleType: SaleType.NOVEMBER_SALE,
      },
    },
    {
      vendorId: '3',
      monthlyPrice: {
        price: '25',
      },
    },
  ],
  isExternalBilling: false,
};

export const mockOneTimeCheapest: IAppPlans = {
  appId: '1234567',
  taxSettings: mockTaxSettings,
  currency: mockCurrencySettings,
  plans: [
    {
      vendorId: '1',
      oneTimePrice: {
        price: '10',
        discountPrice: undefined,
      },
      discount: undefined,
    },
    {
      vendorId: '2',
      monthlyPrice: {
        price: '30',
        discountPrice: '15',
      },
      yearlyPrice: {
        price: '22',
        discountPrice: '11',
      },
      discount: {
        amount: '50',
        type: DiscountType.PERCENTAGE,
        saleType: SaleType.NOVEMBER_SALE,
      },
    },
    {
      vendorId: '3',
      monthlyPrice: {
        price: '25',
      },
      yearlyPrice: {
        price: '20',
      },
    },
  ],
  isExternalBilling: false,
};

export const mockMeteredCheapest: IAppPlans = {
  appId: '1234567',
  taxSettings: mockTaxSettings,
  currency: mockCurrencySettings,
  plans: [
    {
      vendorId: '1',
      meterdBilling: {
        baseFee: '10',
        discountBaseFee: '6',
        outline: 'metered billing test',
      },
      discount: {
        amount: '40',
        type: DiscountType.PERCENTAGE,
        saleType: SaleType.NOVEMBER_SALE,
      },
    },
    {
      vendorId: '2',
      monthlyPrice: {
        price: '30',
        discountPrice: '18',
      },
      discount: {
        amount: '40',
        type: DiscountType.PERCENTAGE,
        saleType: SaleType.NOVEMBER_SALE,
      },
    },
    {
      vendorId: '3',
      yearlyPrice: {
        price: '25',
        discountPrice: '15',
      },
      discount: {
        amount: '40',
        type: DiscountType.PERCENTAGE,
        saleType: SaleType.NOVEMBER_SALE,
      },
    },
  ],
  isExternalBilling: false,
};

export const mockPlansWithNoDiscounts: IAppPlans = {
  appId: '1234567',
  taxSettings: mockTaxSettings,
  currency: mockCurrencySettings,
  plans: [
    {
      vendorId: '1',
      monthlyPrice: {
        price: '90',
      },
      yearlyPrice: {
        price: '100',
      },
    },
    {
      vendorId: '2',
      monthlyPrice: {
        price: '20',
      },
      yearlyPrice: {
        price: '16',
      },
    },
    {
      vendorId: 'basic',
    },
    {
      vendorId: '4',
      meterdBilling: {
        baseFee: '10',
        discountBaseFee: '6',
        outline: 'metered billing test',
      },
    },
  ],
  isExternalBilling: false,
};

export const PricingModelMock: IPricingModel = {
  appId: '1234567',
  freeTrialDays: 0,
  isExternalPricing: false,
  plans: [
    {
      vendorId: '1',
      name: 'test1',
      isVisible: true,
      isFree: false,
      description: { benefits: [], descriptionType: EPlanDescription.BENEFITS },
    },
    {
      vendorId: '2',
      name: 'test2',
      isVisible: true,
      isFree: false,
      description: { benefits: [], descriptionType: EPlanDescription.BENEFITS },
    },
    {
      vendorId: '3',
      name: 'test3',
      isVisible: true,
      isFree: false,
      description: { benefits: [], descriptionType: EPlanDescription.BENEFITS },
    },
  ],
  hasDynamicPlan: false,
};

export const PricingModelMockWithFree: IPricingModel = {
  appId: '1234567',
  freeTrialDays: 0,
  isExternalPricing: false,
  plans: [
    {
      vendorId: '1',
      name: 'test1',
      isVisible: true,
      isFree: false,
      description: { benefits: [], descriptionType: EPlanDescription.BENEFITS },
      monthlyPrice: '10.00',
      yearlyPrice: '8.00',
    },
    {
      vendorId: '2',
      name: 'test2',
      isVisible: true,
      isFree: false,
      description: { benefits: [], descriptionType: EPlanDescription.BENEFITS },
      monthlyPrice: '5.00',
      yearlyPrice: '4.00',
    },
    {
      vendorId: 'basic',
      name: 'Basic',
      isVisible: true,
      isFree: true,
      description: { benefits: [], descriptionType: EPlanDescription.BENEFITS },
    },
    {
      vendorId: '4',
      name: 'test4',
      isVisible: true,
      isFree: false,
      description: { benefits: [], descriptionType: EPlanDescription.BENEFITS },
      meterdBilling: {
        baseFee: '23.00',
        outline: 'metered billing test',
      },
    },
  ],
  hasDynamicPlan: false,
};
