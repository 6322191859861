import React from 'react';
import {
  useEssentials,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { useTranslation } from '../../contexts/translations-context';
import { SidebarComponent } from './sidebar-component';
import { SidebarComponentLoader } from './sidebar-skeleton';
import { SidebarService } from './sidebar-service';
import { Placement } from '@wix/app-market-services';

export function Sidebar() {
  const { i18n, biLogger } = useEssentials();
  const { t } = useTranslation();
  const services = useServices();
  const { route, router } = useRouterContext();
  const { metaSiteId, baseURL } = useSiteContext();
  const languageCode = i18n.language;

  if (route.payload?.hideMenu) {
    return null;
  }

  return (
    <QueryDecorator
      queryFn={() =>
        Promise.all([
          services.getDynamicSections({
            placement: Placement.SIDEBAR,
            isMetaSiteId: !!metaSiteId,
          }),
          metaSiteId
            ? services.completeSetupCount({
                hideWixApps: false,
                includeOldBundles: false,
              })
            : 0,
        ])
      }
      queryKey={`sidebar-${languageCode}`}
    >
      {({ data, isLoading }) => {
        if (isLoading) {
          return <SidebarComponentLoader />;
        }

        const [{ sections }, pendingAppsCount] = data;
        const sidebar = new SidebarService({
          sections,
          route,
          router,
          baseURL,
          t,
          biLogger,
          metaSiteId,
        });

        return (
          <SidebarComponent
            sidebarItems={sidebar.sidebarItems}
            isMetaSiteId={!!metaSiteId}
            selectedItemKey={sidebar.selectedItemKey}
            onItemClick={(item, index) => sidebar.onItemClick(item, index)}
            onQuickNavOpen={(item, index) =>
              sidebar.onQuickNavOpen(item, index)
            }
            onSubItemClick={(item, index, parentItem) =>
              sidebar.onSubItemClick(item, index, parentItem)
            }
            pendingAppsCount={pendingAppsCount}
            shouldShowHeader={sidebar.shouldShowHeader}
            shouldShowFooter={sidebar.shouldShowFooter}
            onFooterClick={() => sidebar.onFooterClick()}
          />
        );
      }}
    </QueryDecorator>
  );
}
