import * as React from 'react';
import type { Path } from '../..';
import s from './menu-item.scss';
import { Box, SidebarSectionItem } from '@wix/design-system';
import { AppMarketLink } from '../app-market-link';
import { composer } from '../../component-decorators/composer';
import { biLogger } from '../../bi';
import { appMarketMenuNavigation } from '@wix/bi-logger-app-market-data/v2';

import { config } from '../../config';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';

export interface IMenuItemProps {
  marketplaceStore?: MarketplaceStore;
  path: Path;
  name: string;
  slug: string;
  index: number;
  holidayColor?: boolean;
}

export const MenuItem = composer()
  .withMarketplaceStore()
  .compose(
    ({
      path,
      name,
      slug,
      index,
      marketplaceStore,
      holidayColor,
    }: IMenuItemProps) => {
      const storeRoute = marketplaceStore.route;
      const isSelected = storeRoute.slug === slug && storeRoute.path === path;
      const route = `${path}/${slug}`;

      const navigateTo = () => {
        biLogger.report(
          appMarketMenuNavigation({
            menu_item_label: name,
            index,
          }),
        );
        config.goto({
          path,
          slug,
          referral: 'menu_navigation',
        });
      };
      return (
        <Box className={s.menuItem}>
          <SidebarSectionItem
            dataHook={`${slug}-menu-item`}
            onClick={navigateTo}
            selected={isSelected}
          >
            <AppMarketLink
              to={route}
              className={holidayColor ? s.menuItemLinkHoliday : s.menuItemLink}
            >
              <Box align="space-between" verticalAlign="middle">
                {name}
              </Box>
            </AppMarketLink>
          </SidebarSectionItem>
        </Box>
      );
    },
  );
