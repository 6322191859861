import React from 'react';
import style from './sale-banner.module.scss';
import { Box, Button, Divider, Text } from '@wix/design-system';
import { Countdown } from './countdown';
import type { ExternalComponentProps } from '../types';
import { useRemainingTime } from './hooks/use-remaining-time';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import { useDateFormatter } from './hooks/use-date-formatter';
import { Trans } from '@wix/wix-i18n-config';
import { SaleEndDate } from './constants/sale-end-date';

type NarrowSaleBannerProps = {
  layout?: 'narrow';
  onClick?: () => void;
};
type WideSaleBannerProps = {
  layout?: 'wide';
  onClick?: never;
};

export type SaleBannerProps = (NarrowSaleBannerProps | WideSaleBannerProps) &
  ExternalComponentProps;

function SaleBannerComponent({ layout = 'narrow', onClick }: SaleBannerProps) {
  const remainingTime = useRemainingTime(SaleEndDate);

  const isCtaVisible = !!onClick;

  if (!remainingTime) {
    return null;
  }

  return (
    <div className={style.saleBannerContainer}>
      <Box
        dataHook="sale-banner"
        className={`${style.banner} ${style[`${layout}Layout`]} ${
          isCtaVisible ? style.withCta : ''
        }`}
        direction="vertical"
      >
        <div className={style.bannerBackground} />
        <Box className={style.bannerContent} align="space-between">
          <Box className={style.bannerLeftSection}>
            <Box className={style.bannerHeadingGroup}>
              <BannerTitle />
              <BannerSubtitle />
            </Box>

            {isCtaVisible ? (
              <BannerCTA onClick={onClick} />
            ) : (
              <BannerDisclaimer />
            )}
          </Box>
          <Box className={style.bannerRightSection}>
            <Box className={style.countdownContainer} verticalAlign="middle">
              <Countdown
                size={layout === 'narrow' ? 'large' : 'small'}
                days={remainingTime.days}
                hours={remainingTime.hours}
                minutes={remainingTime.minutes}
              />
            </Box>

            {isCtaVisible ? (
              <>
                <Box className={style.bannerDividerContainer} padding="0 12px">
                  <Divider
                    className={style.bannerDivider}
                    direction="vertical"
                    skin="dark"
                  />
                </Box>

                <Box
                  className={style.bannerDisclaimerContainer}
                  verticalAlign="middle"
                >
                  <BannerDisclaimer />
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

function BannerTitle() {
  const { t } = useEssentials();
  return <span className={style.bannerTitle}>{t('sale.banner.title')}</span>;
}

function BannerSubtitle() {
  const { t, i18n } = useEssentials();

  return (
    <span className={style.bannerSubtitle}>
      <Trans
        t={t}
        i18n={i18n}
        i18nKey="sale.banner.subtitle"
        components={[<span />]}
      />
    </span>
  );
}

function BannerCTA({ onClick }: { onClick: () => void }) {
  const { t } = useEssentials();
  return (
    <Box width="139px">
      <Button
        dataHook="sale-banner-cta"
        onClick={onClick}
        className={style.bannerCta}
        size="small"
      >
        {t('sale.banner.cta.label')}
      </Button>
    </Box>
  );
}

function BannerDisclaimer() {
  const { t } = useEssentials();
  const dateFormatter = useDateFormatter();

  return (
    <Text className={style.bannerDisclaimer} light size="tiny" weight="normal">
      {t('sale.banner.disclaimer', {
        date: dateFormatter(SaleEndDate),
      })}
    </Text>
  );
}

export const SaleBanner = withEssentialsContext(SaleBannerComponent);
