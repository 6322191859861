import React, { useState } from 'react';
import { TextButton, Box } from '@wix/design-system';
import { ShowSidebar, HideSidebar } from '@wix/wix-ui-icons-common';
import { useConfigContext } from '../../config-context';
import { composer } from '../../component-decorators/composer';
import { appMarketShowHideMenuClick } from '@wix/bi-logger-app-market-data/v2';
import { biLogger } from '../../bi';
import { Origin } from '../../enums/marketplace-enums';
import { config } from '../../config';
import {
  getPathForBi,
  mapAppMarketEntryPointToBiValue,
} from '../../common/bi-events';
import { AppMarketPage } from '../..';

export const SidebarVisibilityToggle = composer()
  .withMarketplaceStore()
  .withExperiments()
  .withTranslation()
  .compose(({ t, marketplaceStore, experiments }) => {
    const shouldStartWithOpenBmSideBar = experiments.enabled(
      'specs.app-market.showBMSideBar',
    );
    const initialValue = shouldStartWithOpenBmSideBar ? true : false;
    const [showBMSideBar, setShowBMSidebar] = useState(initialValue);
    const { showSidebar, hideSidebar, route } = useConfigContext();

    const onToggleSidebarVisibility = () => {
      biLogger.report(
        appMarketShowHideMenuClick({
          type: showBMSideBar ? 'show' : 'hide',
          msid: marketplaceStore?.metaSiteId,
          market: config.origin === Origin.BIZMGR ? 'dashboard' : origin,
          page_type:
            getPathForBi(marketplaceStore?.route?.path) ??
            AppMarketPage.HOMEPAGE,
          entry_point:
            mapAppMarketEntryPointToBiValue[route.entryPointToAppMarket],
        }),
      );
      if (!showBMSideBar) {
        showSidebar();
        setShowBMSidebar(true);
      } else {
        hideSidebar();
        setShowBMSidebar(false);
      }
    };

    return (
      <Box margin="12px 18px 12px 18px">
        <TextButton
          dataHook="sidebar-visibility-toggle"
          onClick={onToggleSidebarVisibility}
          prefixIcon={
            <Box
              marginRight="SP1"
              borderRadius="50%"
              padding="3px"
              backgroundColor="D10"
              height="30px"
              width="30px"
              color="D80"
              align="center"
              verticalAlign="middle"
              dataHook="sidebar-visibility-toggle-icon"
            >
              {showBMSideBar ? <HideSidebar /> : <ShowSidebar />}
            </Box>
          }
          skin="dark"
          weight="normal"
          size="medium"
        >
          {showBMSideBar
            ? t('sidebar.visibility.toggle.hide.label')
            : t('sidebar.visibility.toggle.show.label')}
        </TextButton>
      </Box>
    );
  });
