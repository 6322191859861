import React from 'react';
import { useEssentials, useRouterContext, useServices } from '../../contexts';
import { queryApps as queryAppsData } from './collection-page-data';
import { AppMarketPage } from '../../enums';
import type { QueryApps } from '../../components/apps-section';
import { AppsSection } from '../../components/apps-section';
import type { QueryAppsFilter } from '@wix/app-market-services';
import type { RoutePath, RoutePayloadMap } from '../../models';
import { QueryDecorator } from '../../decorators';
import { queryKeyBuilder } from '../../components/apps-section/app-section-helper';
import { AppsSectionSkeleton } from '../../components/apps-section/apps-section-skeletons';

export function CollectionAppsSection({ slug }: { slug: string }) {
  const { i18n, experiments } = useEssentials();
  const services = useServices();
  const { route } = useRouterContext();

  const isSale = Boolean(
    (route.payload as RoutePayloadMap[RoutePath.COLLECTION]).isSale,
  );
  const pageName = AppMarketPage.COLLECTION;
  const isSaleCollection =
    (route.payload as RoutePayloadMap[RoutePath.COLLECTION]).slug ===
    'holiday-sale';

  const queryApps: QueryApps = async ({
    offset,
    filter,
  }: {
    offset?: number;
    filter?: QueryAppsFilter;
  }) => {
    const { appGroup, paging } = await queryAppsData({
      services,
      experiments,
      slug,
      languageCode: i18n.language,
      offset,
      filter,
    });

    return {
      appGroup,
      paging,
    };
  };

  return (
    <QueryDecorator
      queryFn={() =>
        queryAppsData({
          services,
          experiments,
          slug,
          languageCode: i18n.language,
          offset: 0,
          filter: { isSale },
        })
      }
      queryKey={queryKeyBuilder({
        queryName: 'initial-apps-fetch',
        language: i18n.language,
        isSale,
        appsLength: 0,
        queryId: slug,
      })}
    >
      {({ data, isLoading }) =>
        isLoading ? (
          <AppsSectionSkeleton showSaleToggle={!isSaleCollection} />
        ) : (
          <AppsSection
            origin={pageName}
            queryApps={queryApps}
            queryId={slug}
            initData={data}
            showSaleToggle={!isSaleCollection}
          />
        )
      }
    </QueryDecorator>
  );
}
