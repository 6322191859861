import * as React from 'react';
import type { IModalProps } from '../modals';
import { config } from '../../../config';
import { biLogger } from '../../../bi';
import {
  appMarketCloseDialogBoxOfUpgradeWixX,
  appMarketUpgradeSiteConnectDomainDialogBox,
  appMarketUpgradeWixSiteClick,
} from '@wix/bi-logger-app-market-data/v2';

import { Modal, MessageBoxMarketerialLayout } from '@wix/design-system';
import type { TFunction } from 'i18next';
import { composer } from '../../../component-decorators/composer';

interface IUpgradeSiteModalProps extends IModalProps {
  t?: TFunction;
}

export const UpgradeSiteModal = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose(
    ({ t, appId, msid, marketplaceStore, appName }: IUpgradeSiteModalProps) => {
      const handleClose = () => {
        biLogger.report(
          appMarketCloseDialogBoxOfUpgradeWixX({
            app_id: appId,
            msid,
          }),
        );
        marketplaceStore.closeAllModals();
      };

      const handleConnect = () => {
        biLogger.report(
          appMarketUpgradeWixSiteClick({
            app_id: appId,
            msid,
          }),
        );
        window.open(
          `https://premium.wix.com/wix/api/premiumStart?siteGuid=${marketplaceStore.metaSiteId}&referralAdditionalInfo=marketplace${config.origin}`,
        );
      };

      biLogger.report(
        appMarketUpgradeSiteConnectDomainDialogBox({
          appId,
          msid,
          stepName: 'upgrade_site',
        }),
      );

      return (
        <Modal isOpen={true}>
          <MessageBoxMarketerialLayout
            title={t('modals-upgradeSite-header', { appName })}
            content={t('modals-upgradeSite-content')}
            imageUrl={`${config.marketplaceStaticsUrl}assets/images/upgrade-site-icon.png`}
            theme="purple"
            primaryButtonLabel={t('modals-upgradeSite-cta')}
            onClose={() => handleClose()}
            onPrimaryButtonClick={() => handleConnect()}
          />
        </Modal>
      );
    },
  );
