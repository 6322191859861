import type {
  DynamicSectionsType,
  OnLinkItemClick,
  OnQuickNavOpen,
  OnSubItemLinkClick,
  SidebarItem,
  SidebarLink,
} from './types';

import type { ConstantSidebarItemData } from './sidebar-constants';
import {
  constantSidebarItemData,
  constantSidebarKeys,
  ESidebarItemTypes,
  manageAppsItemData,
  sidebarReferral,
} from './sidebar-constants';
import type { BiLogger, IRoute, Router } from '../../models';
import { Route, RoutePath } from '../../models';
import type { DynamicSection, SubcategoryLink } from '@wix/app-market-services';
import { SectionType } from '@wix/app-market-services';
import type { TFunction } from 'i18next';
import {
  appMarketMenuNavigation,
  appMarketHoverOverSidebarItemSrc24Evid186,
} from '@wix/bi-logger-app-market-data/v2';
import { parseStringToBi } from '../../bi-services/utils';

/**
 * SidebarService manages the creation and navigation of sidebar items.
 * It maps dynamic-sections into sidebar items, handles item clicks, and generates route-based href links.
 *
 * @class SidebarService
 *
 * @method get selectedItemKey - Returns the key of the currently selected sidebar item based on the route.
 * @method get sidebarItems - Retrieves both dynamic and constant sidebar items.
 * @method get shouldShowHeader - Determines if the header should be shown based on `metaSiteId`.
 * @method get shouldShowFooter - Determines if the footer should be shown based on `metaSiteId`.
 * @method onItemClick - Handles navigation when a sidebar item is clicked.
 * @method onSubItemClick - Handles navigation when a sub-item in the sidebar is clicked.
 * @method onQuickNavOpen - Handles opening quick navigation items, logging interactions.
 * @method onFooterClick - Handles the click event on the "Manage Apps" footer item, logging the interaction and navigating to the `MANAGE_APPS` route.
 */

export class SidebarService {
  private readonly router: Router;
  private readonly route: IRoute;
  private readonly baseURL: string | undefined;
  private readonly t: TFunction;
  private readonly items: SidebarItem[];
  private readonly selectedKey: string;
  private readonly biLogger: BiLogger;
  private readonly showHeader: boolean;
  private readonly showFooter: boolean;

  constructor({
    sections,
    route,
    router,
    baseURL,
    t,
    biLogger,
    metaSiteId,
  }: {
    sections: DynamicSectionsType;
    route: IRoute;
    router: Router;
    baseURL: string | undefined;
    t: TFunction;
    biLogger: BiLogger;
    metaSiteId: string | undefined;
  }) {
    this.router = router;
    this.route = route;
    this.baseURL = baseURL;
    this.t = t;
    this.biLogger = biLogger;
    this.items = this.dynamicSectionToSidebarItem(sections);
    this.selectedKey = this.routeToSidebarItemKey(route);
    this.showHeader = !!metaSiteId;
    this.showFooter = !!metaSiteId;
  }

  get selectedItemKey(): string {
    return this.selectedKey;
  }

  get sidebarItems(): SidebarItem[] {
    return this.items;
  }

  get shouldShowHeader(): boolean {
    return this.showHeader;
  }

  get shouldShowFooter(): boolean {
    return this.showFooter;
  }

  onFooterClick(): void {
    const manageAppsItem = this.sidebarItemDataToSidebarItem({
      sidebarItemData: manageAppsItemData,
    }) as SidebarLink;
    this.onItemClick(manageAppsItem, undefined);
  }

  onItemClick: OnLinkItemClick = (item, index): void => {
    if (this.selectedItemKey === item.key) {
      return;
    }
    this.biLogger.report(
      appMarketMenuNavigation({
        menu_item_label: parseStringToBi(item.key),
        item_type: parseStringToBi(item.route.path),
        index,
        page_type: parseStringToBi(this.route.path),
        page_name: parseStringToBi(this.selectedKey),
      }),
    );
    this.router.navigateTo(item.route);
  };

  onQuickNavOpen: OnQuickNavOpen = (item, index): void => {
    this.biLogger.report(
      appMarketHoverOverSidebarItemSrc24Evid186({
        menu_item_label: parseStringToBi(item.key),
        item_type: parseStringToBi(item.route.path),
        index,
        page_type: parseStringToBi(this.route.path),
        page_name: parseStringToBi(this.selectedKey),
      }),
    );
  };

  onSubItemClick: OnSubItemLinkClick = (item, index, parentItem): void => {
    if (this.selectedItemKey === item.key) {
      return;
    }
    this.biLogger.report(
      appMarketMenuNavigation({
        menu_item_label: parseStringToBi(item.key),
        item_type: parseStringToBi(item.route.path),
        index,
        page_type: parseStringToBi(this.route.path),
        page_name: parseStringToBi(this.selectedKey),
        item_section: parseStringToBi(
          this.routeToSidebarItemKey(parentItem.route),
        ),
      }),
    );
    this.router.navigateTo(item.route);
  };

  private dynamicSectionToSidebarItem(
    section: DynamicSectionsType,
  ): SidebarItem[] {
    const sidebarItems = section.map((_section) =>
      this.mapSidebarSectionToSidebarItem(_section, this.baseURL),
    );
    return this.getConstantSidebarItems().concat(sidebarItems);
  }

  private mapSidebarSectionToSidebarItem(
    section: DynamicSection,
    baseURL: string | undefined,
  ): SidebarItem {
    switch (section.type) {
      case SectionType.SIDEBAR_DIVIDER:
        return {
          type: ESidebarItemTypes.DIVIDER,
        };
      case SectionType.SIDEBAR_TITLE:
        return {
          type: ESidebarItemTypes.TITLE,
          label: section.sidebarTitle?.text ?? '',
        };
      case SectionType.COLLECTION_LINK:
        const collectionRoute: IRoute = {
          path: RoutePath.COLLECTION,
          payload: {
            slug: section.collectionLink?.slug ?? '',
            referral: sidebarReferral,
          },
        };
        return {
          type: ESidebarItemTypes.LINK,
          label: section.collectionLink?.label ?? '',
          key: section.collectionLink?.slug ?? '',
          route: collectionRoute,
          href: this.routeToHref(collectionRoute, baseURL),
          highlighted: !!section.collectionLink?.highlighted,
        };
      case SectionType.CATEGORY_LINK:
        const categoryRoute: IRoute = {
          path: RoutePath.CATEGORY,
          payload: {
            slug: section.categoryLink?.slug ?? '',
            referral: sidebarReferral,
          },
        };
        return {
          type: ESidebarItemTypes.SUB_MENU,
          label: section.categoryLink?.label ?? '',
          key: section.categoryLink?.slug ?? '',
          route: categoryRoute,
          href: this.routeToHref(categoryRoute, baseURL),
          links:
            section.categoryLink?.subCategoryLinks?.map(
              (subCategoryLink: SubcategoryLink) => {
                const subCategoryRoute: IRoute = {
                  path: RoutePath.SUB_CATEGORY,
                  payload: {
                    parentSlug: subCategoryLink.parentCategorySlug ?? '',
                    slug: subCategoryLink.slug ?? '',
                    subCat: subCategoryLink.slug ?? '',
                    referral: sidebarReferral,
                  },
                };
                return {
                  type: ESidebarItemTypes.LINK,
                  label: subCategoryLink.label ?? '',
                  key: subCategoryLink.slug ?? '',
                  route: subCategoryRoute,
                  href: this.routeToHref(subCategoryRoute, baseURL),
                };
              },
            ) ?? ([] as SidebarLink[]),
        };
      default:
        throw new Error(
          `[sidebar-actions] (mapSidebarSectionToSidebarItem) failed: section type: ${section.type} is not supported on sidebar`,
        );
    }
  }

  private getConstantSidebarItems(): SidebarItem[] {
    return constantSidebarItemData.map((item) =>
      this.sidebarItemDataToSidebarItem({ sidebarItemData: item }),
    );
  }

  private sidebarItemDataToSidebarItem({
    sidebarItemData,
  }: {
    sidebarItemData: ConstantSidebarItemData;
  }): SidebarItem {
    const { key, labelTransKey, route } = sidebarItemData;
    return {
      type: ESidebarItemTypes.LINK,
      label: this.t(labelTransKey),
      key,
      route,
      href: this.routeToHref(route, this.baseURL),
    } as SidebarLink;
  }

  private routeToHref(route: IRoute, baseURL: string | undefined): string {
    const { path, payload } = route;
    const currentRoute = new Route({ path, payload, baseURL });
    return currentRoute.toHref();
  }

  private routeToSidebarItemKey(route: IRoute): string {
    const { payload, path } = route;
    const defaultKey = constantSidebarKeys[RoutePath.UNSUPPORTED];

    switch (path) {
      case RoutePath.HOME:
        return constantSidebarKeys.HOME;
      case RoutePath.SEARCH_RESULTS:
        return constantSidebarKeys.SEARCH_RESULTS;
      case RoutePath.MANAGE_APPS:
        return constantSidebarKeys.MANAGE_APPS;
      case RoutePath.COLLECTION:
      case RoutePath.CATEGORY:
      case RoutePath.APP_PAGE:
      case RoutePath.SUB_CATEGORY:
      case RoutePath.DEVELOPER:
        return payload.slug ?? defaultKey;

      default:
        return defaultKey;
    }
  }
}
