import { SidebarBackButton } from '@wix/design-system';
import { config } from '../../config';
import * as React from 'react';
import { composer } from '../../component-decorators/composer';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { biLogger } from '../../bi';
import { getPathForBi } from '../../common/bi-events';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import {
  appMarketMenuNavigation,
  appMarketBackToManageAppsSrc24Evid5,
} from '@wix/bi-logger-app-market-data/v2';

interface IBackMenuItemProps extends Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
}

export const BackMenuItem = composer()
  .withTranslation()
  .withMarketplaceStore()
  .compose(({ t, marketplaceStore }: IBackMenuItemProps) => {
    const { labelKey, referrerNameForBI, navigation } = config.goBackToReferrer;
    const { slug, query, path } = marketplaceStore.route;
    const { metaSiteId } = marketplaceStore;
    function sendBackToReferrerBi() {
      biLogger.report(
        appMarketMenuNavigation({
          menu_item_label: t(labelKey),
        }),
      );
      biLogger.report(
        appMarketBackToManageAppsSrc24Evid5({
          click_origin: 'top',
          msid: metaSiteId,
          tag_name: (slug || query || 'homepage').replace(/-/g, '_'),
          tag_type: getPathForBi(path),
          referral_name: referrerNameForBI,
        }),
      );
    }
    function onBackClick() {
      sendBackToReferrerBi();
      navigation();
    }
    return (
      <SidebarBackButton dataHook="back-button" onClick={onBackClick}>
        {t(labelKey)}
      </SidebarBackButton>
    );
  });
