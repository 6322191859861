import { APPS_LIMIT, SortType, AppGroupType } from '@wix/app-market-services';
import type {
  AppGroupSection,
  IServices,
  QueryAppsFilter,
} from '@wix/app-market-services';
import type { QueryAppsResponse } from '../../components/apps-section';
import type Experiments from '@wix/wix-experiments';

export interface SubCategoryPageHeaderData {
  slug: string;
  parentSlug: string;
  parentName: string;
  primaryTitle: string;
}

export interface SubCategoryPageData {
  slug: string;
  parentSlug: string;
}

export async function getSubCategoryComponentData({
  slug,
  parentSlug,
  services,
  languageCode,
  isSale,
  experiments,
}: {
  services: IServices;
  experiments: Experiments;
  slug: string;
  parentSlug: string;
  languageCode: string;
  isSale: boolean;
}): Promise<QueryAppsResponse> {
  const shouldUseDealerSorting = experiments.enabled(
    'specs.app-market.useDealerSortingInAppsTag',
  );
  const [tags, parentTags] = await Promise.all([
    services.queryTag({ slugs: [slug], languageCode }),
    services.queryTag({ slugs: [parentSlug], languageCode }),
  ]);

  const tag = tags.findBySlug(slug);
  if (tag.id === '') {
    throw new Error(
      `Sub Category with slug ${slug} not found, languageCode: ${languageCode}`,
    );
  }

  const parentTag = parentTags.findBySlug(parentSlug);
  if (parentTag.id === '') {
    throw new Error(
      `Parent Category with slug ${parentSlug} not found, languageCode: ${languageCode}`,
    );
  }

  const tagId = tag.id;

  const tagApps = await services.getAppsByTag({
    tagId,
    sortTypes: shouldUseDealerSorting
      ? [SortType.DEALER]
      : [SortType.COUNTRY_CODE, SortType.APP_MARKET_SCORE],
    paging: {
      limit: APPS_LIMIT,
      offset: 0,
    },
    filter: { isSale },
  });
  const appsData = await services.getAppsByAppIds({
    appIds: tagApps.appIds,
    status: 'PUBLISHED',
  });
  const apps = appsData.toJSON();

  return {
    appGroup: {
      type: AppGroupType.COLLECTION,
      tagSlug: slug,
      apps: apps as AppGroupSection['apps'],
      title: '',
      totalAppsCount: 0,
    },
    paging: {
      total: tagApps.paging.total,
      hasNext: tagApps.paging.hasNext,
    },
  };
}

export async function getSubCategoryHeaderPageData({
  slug,
  parentSlug,
  services,
  languageCode,
}: {
  services: IServices;
  slug: string;
  parentSlug: string;
  languageCode: string;
}): Promise<SubCategoryPageHeaderData> {
  const [tags, parentTags] = await Promise.all([
    services.queryTag({ slugs: [slug], languageCode }),
    services.queryTag({ slugs: [parentSlug], languageCode }),
  ]);

  const tag = tags.findBySlug(slug);
  if (tag.id === '') {
    throw new Error(
      `Sub Category with slug ${slug} not found, languageCode: ${languageCode}`,
    );
  }

  const parentTag = parentTags.findBySlug(parentSlug);
  if (parentTag.id === '') {
    throw new Error(
      `Parent Category with slug ${parentSlug} not found, languageCode: ${languageCode}`,
    );
  }

  return {
    slug,
    parentSlug,
    parentName: parentTag.name,
    primaryTitle: tag.name,
  };
}

export async function queryApps({
  slug,
  services,
  experiments,
  languageCode,
  offset = 0,
  filter,
}: {
  services: IServices;
  experiments: Experiments;
  slug: string;
  languageCode: string;
  offset?: number;
  filter?: QueryAppsFilter;
}): Promise<QueryAppsResponse> {
  const tags = await services.queryTag({ slugs: [slug], languageCode });
  const tag = tags.findBySlug(slug);
  if (tag.id === '') {
    throw new Error(
      `Sub Category with slug ${slug} not found, languageCode: ${languageCode}`,
    );
  }

  const tagId = tag.id;
  const shouldUseDealerSorting = experiments.enabled(
    'specs.app-market.useDealerSortingInAppsTag',
  );
  const tagApps = await services.getAppsByTag({
    tagId,
    sortTypes: shouldUseDealerSorting
      ? [SortType.DEALER]
      : [SortType.COUNTRY_CODE, SortType.APP_MARKET_SCORE],
    paging: {
      limit: APPS_LIMIT,
      offset,
    },
    filter,
  });
  const appsData = await services.getAppsByAppIds({
    appIds: tagApps.appIds,
    status: 'PUBLISHED',
  });
  const apps = appsData.toJSON();

  return {
    appGroup: {
      type: AppGroupType.COLLECTION,
      tagSlug: slug,
      apps: apps as AppGroupSection['apps'],
      title: '',
      totalAppsCount: 0,
    },
    paging: {
      total: tagApps.paging.total,
      hasNext: tagApps.paging.hasNext,
    },
  };
}
